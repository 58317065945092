<template>
  <div class="signin-page">
    <!-- Header Section -->
    <v-container fluid class="signin-section py-md-8 py-6">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="text-center mx-auto">
          <v-img :src="logo" max-height="60px" contain class="mx-auto mb-4 signin-logo" />
          <h1 class="signin-title font-weight-bold mb-4">{{ $appName }}</h1>
          
          <v-card
            class="mx-auto mt-8 signin-card"
            max-width="500px"
            rounded="lg"
            elevation="10"
            :loading="loading"
          >
            <v-container class="py-8 px-md-8 px-6">
              <h2 class="section-title font-weight-bold mb-6">
                {{ $route.meta.signup ? 'Create Account' : 'Welcome Back' }}
              </h2>
              
              <v-form 
                ref="signup_form"
                class="mt-6"
                @submit.prevent="() => onSignIn()"
              >
                <v-text-field
                  label="Your name"
                  v-model="name"
                  rounded
                  outlined
                  class="mb-0 input-field"
                  :rules="[v => v && v.length > 3 || 'Required']"
                  v-if="$route.meta.signup"
                />
                <v-switch
                  v-if="$route.meta.signup"
                  label="We are a business"
                  v-model="is_business"
                  class="mt-0 mb-4"
                  dense
                  />
                <v-text-field
                  label="Company name"
                  v-model="company_name"
                  rounded
                  outlined
                  class="mb-4 input-field"
                  :rules="[v => v && v.length > 3 || 'Required']"
                  v-if="is_business"
                />
                <v-select
                  label="Country"
                  v-model="country_code"
                  :items="countries"
                  item-text="name"
                  item-value="code"
                  rounded
                  outlined
                  class="mb-4 input-field"
                  :rules="[v => !!v || 'Required']"
                  v-if="is_business"
                />
                <v-text-field
                  label="Email"
                  v-model="email"
                  rounded
                  outlined
                  class="mb-4 input-field"
                  :rules="rules.user.email"
                />
                <v-text-field
                  label="Password"
                  v-model="password"
                  type="password"
                  rounded
                  outlined
                  class="mb-6 input-field"
                  :rules="rules.user.password"
                />
                
                <v-btn
                  type="submit"
                  color="primary"
                  rounded
                  x-large
                  block
                  class="mt-6 signin-btn"
                  elevation="2"
                >
                  {{ $route.meta.signup ? 'Create Account' : 'Sign In' }}
                </v-btn>
                
                <div class="text-center mt-8">
                  <p class="mb-4 account-text">
                    {{ $route.meta.signup ? 'Already have an account?' : 'Don\'t have an account yet?' }}
                  </p>
                  
                  <v-btn 
                    outlined
                    rounded
                    color="primary"
                    class="switch-btn"
                    v-if="!$route.meta.signup" 
                    @click="$router.push('/signup')"
                  >
                    Sign Up Free
                  </v-btn>
                  
                  <v-btn 
                    outlined
                    rounded
                    color="primary"
                    class="switch-btn"
                    v-else
                    @click="$router.push('/signin')"
                  >
                    Sign In
                  </v-btn>
                  
                  <v-btn 
                    text
                    block
                    class="mt-6 forgot-btn"
                    v-if="!$route.meta.signup" 
                    @click="$router.push('/password')"
                  >
                    Forgot password?
                  </v-btn>
                </div>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Footer -->
    <v-footer class="pa-4 footer">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <p class="caption mb-0">© {{ new Date().getFullYear() }} Zodiapps AB. All rights reserved.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import rules from '@/utils/rules'
import countries from '@/assets/countries.js'

export default {
  data() {
    return {
      logo: require('@/assets/favicon-192.png'),
      loading: false,
      email: '',
      password: '',
      name: '',
      rules,
      is_business: false,
      company_name: '',
      country_code: '',
      countries,
    }
  },
  mounted() {
    if(this.$route.query?.email) this.email = this.$route.query.email
  },
  methods: {
    async onSignIn() {
      this.email = this.email.toLowerCase().replace(/ /gi, '')
      if(!this.$refs.signup_form.validate()) return
      try {
        this.loading = true
        const auth = this.$route.meta.signup ? 
          await this.$auth.signup(this.email, this.password) :
          await this.$auth.login(this.email, this.password)
        if(!auth) throw new Error('Unable to login')
        const user = await this.$store.dispatch('user/get_user', {
          name: this.name,
          email: this.email,
          country_code: this.is_business && this.country_code,
          company_name: this.is_business && this.company_name
        })
        
        await firebase.auth().currentUser.getIdToken(true)
        console.log('AFTER', user)
        if(user.companies.length) {
          await this.$store.dispatch('warehouse/set_company', user.companies[0].id)
          this.$store.dispatch('warehouse/get_company_plan').then(() => {
            this.$store.dispatch('order/get_order_count')
            this.$store.dispatch('product/get_products', {})
          })
        }
        this.$router.push('/auth/dashboard')
      } catch (e) {
        this.$store.commit('app/SET_ERROR', e)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.signin-page {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Signin Section */
.signin-section {
  background: linear-gradient(135deg, #f6f6f7 0%, #e9e9eb 100%);
  flex: 1;
  display: flex;
  align-items: center;
}

.signin-logo {
  animation: fadeIn 0.8s ease-out;
}

.signin-title {
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
  animation: fadeInUp 0.8s ease-out;
}

.signin-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 1s ease-out 0.2s;
  animation-fill-mode: both;
}

.signin-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15) !important;
}

.section-title {
  font-size: 1.8rem;
  letter-spacing: -0.5px;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #4caf50;
  border-radius: 3px;
}

.input-field {
  transition: transform 0.2s ease;
}

.input-field:focus {
  transform: translateY(-2px);
}

.signin-btn {
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.signin-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(76, 175, 80, 0.2) !important;
}

.switch-btn {
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.switch-btn:hover {
  transform: translateY(-2px);
  background-color: rgba(76, 175, 80, 0.1);
}

.forgot-btn {
  transition: color 0.3s ease;
}

.forgot-btn:hover {
  color: #4caf50 !important;
}

.account-text {
  color: rgba(0, 0, 0, 0.6);
}

/* Footer */
.footer {
  background-color: #f6f6f7;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 960px) {
  .signin-title {
    font-size: 2rem;
  }
  
  .section-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .signin-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .signin-title {
    font-size: 1.75rem;
  }
  
  .section-title {
    font-size: 1.25rem;
  }
}

/* Accessibility Improvements */
a:focus, button:focus {
  outline: 2px solid #4caf50;
  outline-offset: 2px;
}
</style>
