export default [ 
  {name: 'Afghanistan', code: 'AF', code3: 'AFG'}, 
  {name: 'Åland Islands', code: 'AX', code3: 'ALA'}, 
  {name: 'Albania', code: 'AL', code3: 'ALB'}, 
  {name: 'Algeria', code: 'DZ', code3: 'DZA'}, 
  {name: 'American Samoa', code: 'AS', code3: 'ASM'}, 
  {name: 'Andorra', code: 'AD', code3: 'AND'}, 
  {name: 'Angola', code: 'AO', code3: 'AGO'}, 
  {name: 'Anguilla', code: 'AI', code3: 'AIA'}, 
  {name: 'Antarctica', code: 'AQ', code3: 'ATA'}, 
  {name: 'Antigua and Barbuda', code: 'AG', code3: 'ATG'}, 
  {name: 'Argentina', code: 'AR', code3: 'ARG'}, 
  {name: 'Armenia', code: 'AM', code3: 'ARM'}, 
  {name: 'Aruba', code: 'AW', code3: 'ABW'}, 
  {name: 'Australia', code: 'AU', code3: 'AUS'}, 
  {name: 'Austria', code: 'AT', code3: 'AUT'}, 
  {name: 'Azerbaijan', code: 'AZ', code3: 'AZE'}, 
  {name: 'Bahamas', code: 'BS', code3: 'BHS'}, 
  {name: 'Bahrain', code: 'BH', code3: 'BHR'}, 
  {name: 'Bangladesh', code: 'BD', code3: 'BGD'}, 
  {name: 'Barbados', code: 'BB', code3: 'BRB'}, 
  {name: 'Belarus', code: 'BY', code3: 'BLR'}, 
  {name: 'Belgium', code: 'BE', code3: 'BEL'}, 
  {name: 'Belize', code: 'BZ', code3: 'BLZ'}, 
  {name: 'Benin', code: 'BJ', code3: 'BEN'}, 
  {name: 'Bermuda', code: 'BM', code3: 'BMU'}, 
  {name: 'Bhutan', code: 'BT', code3: 'BTN'}, 
  {name: 'Bolivia', code: 'BO', code3: 'BOL'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA', code3: 'BIH'}, 
  {name: 'Botswana', code: 'BW', code3: 'BWA'}, 
  {name: 'Bouvet Island', code: 'BV', code3: 'BVT'}, 
  {name: 'Brazil', code: 'BR', code3: 'BRA'}, 
  {name: 'British Indian Ocean Territory', code: 'IO', code3: 'IOT'}, 
  {name: 'Brunei Darussalam', code: 'BN', code3: 'BRN'}, 
  {name: 'Bulgaria', code: 'BG', code3: 'BGR'}, 
  {name: 'Burkina Faso', code: 'BF', code3: 'BFA'}, 
  {name: 'Burundi', code: 'BI', code3: 'BDI'}, 
  {name: 'Cambodia', code: 'KH', code3: 'KHM'}, 
  {name: 'Cameroon', code: 'CM', code3: 'CMR'}, 
  {name: 'Canada', code: 'CA', code3: 'CAN'}, 
  {name: 'Cape Verde', code: 'CV', code3: 'CPV'}, 
  {name: 'Cayman Islands', code: 'KY', code3: 'CYM'}, 
  {name: 'Central African Republic', code: 'CF', code3: 'CAF'}, 
  {name: 'Chad', code: 'TD', code3: 'TCD'}, 
  {name: 'Chile', code: 'CL', code3: 'CHL'}, 
  {name: 'China', code: 'CN', code3: 'CHN'}, 
  {name: 'Christmas Island', code: 'CX', code3: 'CXR'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CC', code3: 'CCK'}, 
  {name: 'Colombia', code: 'CO', code3: 'COL'}, 
  {name: 'Comoros', code: 'KM', code3: 'COM'}, 
  {name: 'Congo', code: 'CG', code3: 'COG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD', code3: 'COD'}, 
  {name: 'Cook Islands', code: 'CK', code3: 'COK'}, 
  {name: 'Costa Rica', code: 'CR', code3: 'CRI'}, 
  {name: 'Cote D\'Ivoire', code: 'CI', code3: 'CIV'}, 
  {name: 'Croatia', code: 'HR', code3: 'HRV'}, 
  {name: 'Cuba', code: 'CU', code3: 'CUB'}, 
  {name: 'Cyprus', code: 'CY', code3: 'CYP'}, 
  {name: 'Czech Republic', code: 'CZ', code3: 'CZE'}, 
  {name: 'Denmark', code: 'DK', code3: 'DNK'}, 
  {name: 'Djibouti', code: 'DJ', code3: 'DJI'}, 
  {name: 'Dominica', code: 'DM', code3: 'DMA'}, 
  {name: 'Dominican Republic', code: 'DO', code3: 'DOM'}, 
  {name: 'Ecuador', code: 'EC', code3: 'ECU'}, 
  {name: 'Egypt', code: 'EG', code3: 'EGY'}, 
  {name: 'El Salvador', code: 'SV', code3: 'SLV'}, 
  {name: 'Equatorial Guinea', code: 'GQ', code3: 'GNQ'}, 
  {name: 'Eritrea', code: 'ER', code3: 'ERI'}, 
  {name: 'Estonia', code: 'EE', code3: 'EST'}, 
  {name: 'Ethiopia', code: 'ET', code3: 'ETH'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK', code3: 'FLK'}, 
  {name: 'Faroe Islands', code: 'FO', code3: 'FRO'}, 
  {name: 'Fiji', code: 'FJ', code3: 'FJI'}, 
  {name: 'Finland', code: 'FI', code3: 'FIN'}, 
  {name: 'France', code: 'FR', code3: 'FRA'}, 
  {name: 'French Guiana', code: 'GF', code3: 'GUF'}, 
  {name: 'French Polynesia', code: 'PF', code3: 'PYF'}, 
  {name: 'French Southern Territories', code: 'TF', code3: 'ATF'}, 
  {name: 'Gabon', code: 'GA', code3: 'GAB'}, 
  {name: 'Gambia', code: 'GM', code3: 'GMB'}, 
  {name: 'Georgia', code: 'GE', code3: 'GEO'}, 
  {name: 'Germany', code: 'DE', code3: 'DEU'}, 
  {name: 'Ghana', code: 'GH', code3: 'GHA'}, 
  {name: 'Gibraltar', code: 'GI', code3: 'GIB'}, 
  {name: 'Greece', code: 'GR', code3: 'GRC'}, 
  {name: 'Greenland', code: 'GL', code3: 'GRL'}, 
  {name: 'Grenada', code: 'GD', code3: 'GRD'}, 
  {name: 'Guadeloupe', code: 'GP', code3: 'GLP'}, 
  {name: 'Guam', code: 'GU', code3: 'GUM'}, 
  {name: 'Guatemala', code: 'GT', code3: 'GTM'}, 
  {name: 'Guernsey', code: 'GG', code3: 'GGY'}, 
  {name: 'Guinea', code: 'GN', code3: 'GIN'}, 
  {name: 'Guinea-Bissau', code: 'GW', code3: 'GNB'}, 
  {name: 'Guyana', code: 'GY', code3: 'GUY'}, 
  {name: 'Haiti', code: 'HT', code3: 'HTI'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM', code3: 'HMD'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA', code3: 'VAT'}, 
  {name: 'Honduras', code: 'HN', code3: 'HND'}, 
  {name: 'Hong Kong', code: 'HK', code3: 'HKG'}, 
  {name: 'Hungary', code: 'HU', code3: 'HUN'}, 
  {name: 'Iceland', code: 'IS', code3: 'ISL'}, 
  {name: 'India', code: 'IN', code3: 'IND'}, 
  {name: 'Indonesia', code: 'ID', code3: 'IDN'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR', code3: 'IRN'}, 
  {name: 'Iraq', code: 'IQ', code3: 'IRQ'}, 
  {name: 'Ireland', code: 'IE', code3: 'IRL'}, 
  {name: 'Isle of Man', code: 'IM', code3: 'IMN'}, 
  {name: 'Israel', code: 'IL', code3: 'ISR'}, 
  {name: 'Italy', code: 'IT', code3: 'ITA'}, 
  {name: 'Jamaica', code: 'JM', code3: 'JAM'}, 
  {name: 'Japan', code: 'JP', code3: 'JPN'}, 
  {name: 'Jersey', code: 'JE', code3: 'JEY'}, 
  {name: 'Jordan', code: 'JO', code3: 'JOR'}, 
  {name: 'Kazakhstan', code: 'KZ', code3: 'KAZ'}, 
  {name: 'Kenya', code: 'KE', code3: 'KEN'}, 
  {name: 'Kiribati', code: 'KI', code3: 'KIR'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP', code3: 'PRK'}, 
  {name: 'Korea, Republic of', code: 'KR', code3: 'KOR'}, 
  {name: 'Kuwait', code: 'KW', code3: 'KWT'}, 
  {name: 'Kyrgyzstan', code: 'KG', code3: 'KGZ'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA', code3: 'LAO'}, 
  {name: 'Latvia', code: 'LV', code3: 'LVA'}, 
  {name: 'Lebanon', code: 'LB', code3: 'LBN'}, 
  {name: 'Lesotho', code: 'LS', code3: 'LSO'}, 
  {name: 'Liberia', code: 'LR', code3: 'LBR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY', code3: 'LBY'}, 
  {name: 'Liechtenstein', code: 'LI', code3: 'LIE'}, 
  {name: 'Lithuania', code: 'LT', code3: 'LTU'}, 
  {name: 'Luxembourg', code: 'LU', code3: 'LUX'}, 
  {name: 'Macao', code: 'MO', code3: 'MAC'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', code3: 'MKD'}, 
  {name: 'Madagascar', code: 'MG', code3: 'MDG'}, 
  {name: 'Malawi', code: 'MW', code3: 'MWI'}, 
  {name: 'Malaysia', code: 'MY', code3: 'MYS'}, 
  {name: 'Maldives', code: 'MV', code3: 'MDV'}, 
  {name: 'Mali', code: 'ML', code3: 'MLI'}, 
  {name: 'Malta', code: 'MT', code3: 'MLT'}, 
  {name: 'Marshall Islands', code: 'MH', code3: 'MHL'}, 
  {name: 'Martinique', code: 'MQ', code3: 'MTQ'}, 
  {name: 'Mauritania', code: 'MR', code3: 'MRT'}, 
  {name: 'Mauritius', code: 'MU', code3: 'MUS'}, 
  {name: 'Mayotte', code: 'YT', code3: 'MYT'}, 
  {name: 'Mexico', code: 'MX', code3: 'MEX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM', code3: 'FSM'}, 
  {name: 'Moldova, Republic of', code: 'MD', code3: 'MDA'}, 
  {name: 'Monaco', code: 'MC', code3: 'MCO'}, 
  {name: 'Mongolia', code: 'MN', code3: 'MNG'}, 
  {name: 'Montserrat', code: 'MS', code3: 'MSR'}, 
  {name: 'Morocco', code: 'MA', code3: 'MAR'}, 
  {name: 'Mozambique', code: 'MZ', code3: 'MOZ'}, 
  {name: 'Myanmar', code: 'MM', code3: 'MMR'}, 
  {name: 'Namibia', code: 'NA', code3: 'NAM'}, 
  {name: 'Nauru', code: 'NR', code3: 'NRU'}, 
  {name: 'Nepal', code: 'NP', code3: 'NPL'}, 
  {name: 'Netherlands', code: 'NL', code3: 'NLD'}, 
  {name: 'Netherlands Antilles', code: 'AN', code3: 'ANT'}, 
  {name: 'New Caledonia', code: 'NC', code3: 'NCL'}, 
  {name: 'New Zealand', code: 'NZ', code3: 'NZL'}, 
  {name: 'Nicaragua', code: 'NI', code3: 'NIC'}, 
  {name: 'Niger', code: 'NE', code3: 'NER'}, 
  {name: 'Nigeria', code: 'NG', code3: 'NGA'}, 
  {name: 'Niue', code: 'NU', code3: 'NIU'}, 
  {name: 'Norfolk Island', code: 'NF', code3: 'NFK'}, 
  {name: 'Northern Mariana Islands', code: 'MP', code3: 'MNP'}, 
  {name: 'Norway', code: 'NO', code3: 'NOR'}, 
  {name: 'Oman', code: 'OM', code3: 'OMN'}, 
  {name: 'Pakistan', code: 'PK', code3: 'PAK'}, 
  {name: 'Palau', code: 'PW', code3: 'PLW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS', code3: 'PSE'}, 
  {name: 'Panama', code: 'PA', code3: 'PAN'}, 
  {name: 'Papua New Guinea', code: 'PG', code3: 'PNG'}, 
  {name: 'Paraguay', code: 'PY', code3: 'PRY'}, 
  {name: 'Peru', code: 'PE', code3: 'PER'}, 
  {name: 'Philippines', code: 'PH', code3: 'PHL'}, 
  {name: 'Pitcairn', code: 'PN', code3: 'PCN'}, 
  {name: 'Poland', code: 'PL', code3: 'POL'}, 
  {name: 'Portugal', code: 'PT', code3: 'PRT'}, 
  {name: 'Puerto Rico', code: 'PR', code3: 'PRI'}, 
  {name: 'Qatar', code: 'QA', code3: 'QAT'}, 
  {name: 'Reunion', code: 'RE', code3: 'REU'}, 
  {name: 'Romania', code: 'RO', code3: 'ROU'}, 
  {name: 'Russian Federation', code: 'RU', code3: 'RUS'}, 
  {name: 'RWANDA', code: 'RW', code3: 'RWA'}, 
  {name: 'Saint Helena', code: 'SH', code3: 'SHN'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN', code3: 'KNA'}, 
  {name: 'Saint Lucia', code: 'LC', code3: 'LCA'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM', code3: 'SPM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC', code3: 'VCT'}, 
  {name: 'Samoa', code: 'WS', code3: 'WSM'}, 
  {name: 'San Marino', code: 'SM', code3: 'SMR'}, 
  {name: 'Sao Tome and Principe', code: 'ST', code3: 'STP'}, 
  {name: 'Saudi Arabia', code: 'SA', code3: 'SAU'}, 
  {name: 'Senegal', code: 'SN', code3: 'SEN'}, 
  {name: 'Serbia', code: 'RS', code3: 'SRB'}, 
  {name: 'Montenegro', code: 'ME', code3: 'MNE'}, 
  {name: 'Seychelles', code: 'SC', code3: 'SYC'}, 
  {name: 'Sierra Leone', code: 'SL', code3: 'SLE'}, 
  {name: 'Singapore', code: 'SG', code3: 'SGP'}, 
  {name: 'Slovakia', code: 'SK', code3: 'SVK'}, 
  {name: 'Slovenia', code: 'SI', code3: 'SVN'}, 
  {name: 'Solomon Islands', code: 'SB', code3: 'SLB'}, 
  {name: 'Somalia', code: 'SO', code3: 'SOM'}, 
  {name: 'South Africa', code: 'ZA', code3: 'ZAF'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS', code3: 'SGS'}, 
  {name: 'Spain', code: 'ES', code3: 'ESP'}, 
  {name: 'Sri Lanka', code: 'LK', code3: 'LKA'}, 
  {name: 'Sudan', code: 'SD', code3: 'SDN'}, 
  {name: 'Suriname', code: 'SR', code3: 'SUR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ', code3: 'SJM'}, 
  {name: 'Swaziland', code: 'SZ', code3: 'SWZ'}, 
  {name: 'Sweden', code: 'SE', code3: 'SWE'}, 
  {name: 'Switzerland', code: 'CH', code3: 'CHE'}, 
  {name: 'Syrian Arab Republic', code: 'SY', code3: 'SYR'}, 
  {name: 'Taiwan, Province of China', code: 'TW', code3: 'TWN'}, 
  {name: 'Tajikistan', code: 'TJ', code3: 'TJK'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ', code3: 'TZA'}, 
  {name: 'Thailand', code: 'TH', code3: 'THA'}, 
  {name: 'Timor-Leste', code: 'TL', code3: 'TLS'}, 
  {name: 'Togo', code: 'TG', code3: 'TGO'}, 
  {name: 'Tokelau', code: 'TK', code3: 'TKL'}, 
  {name: 'Tonga', code: 'TO', code3: 'TON'}, 
  {name: 'Trinidad and Tobago', code: 'TT', code3: 'TTO'}, 
  {name: 'Tunisia', code: 'TN', code3: 'TUN'}, 
  {name: 'Turkey', code: 'TR', code3: 'TUR'}, 
  {name: 'Turkmenistan', code: 'TM', code3: 'TKM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC', code3: 'TCA'}, 
  {name: 'Tuvalu', code: 'TV', code3: 'TUV'}, 
  {name: 'Uganda', code: 'UG', code3: 'UGA'}, 
  {name: 'Ukraine', code: 'UA', code3: 'UKR'}, 
  {name: 'United Arab Emirates', code: 'AE', code3: 'ARE'}, 
  {name: 'United Kingdom', code: 'GB', code3: 'GBR'}, 
  {name: 'United States', code: 'US', code3: 'USA'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM', code3: 'UMI'}, 
  {name: 'Uruguay', code: 'UY', code3: 'URY'}, 
  {name: 'Uzbekistan', code: 'UZ', code3: 'UZB'}, 
  {name: 'Vanuatu', code: 'VU', code3: 'VUT'}, 
  {name: 'Venezuela', code: 'VE', code3: 'VEN'}, 
  {name: 'Viet Nam', code: 'VN', code3: 'VNM'}, 
  {name: 'Virgin Islands, British', code: 'VG', code3: 'VGB'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI', code3: 'VIR'}, 
  {name: 'Wallis and Futuna', code: 'WF', code3: 'WLF'}, 
  {name: 'Western Sahara', code: 'EH', code3: 'ESH'}, 
  {name: 'Yemen', code: 'YE', code3: 'YEM'}, 
  {name: 'Zambia', code: 'ZM', code3: 'ZMB'}, 
  {name: 'Zimbabwe', code: 'ZW', code3: 'ZWE'} 
]
